<template>
  <DashboardTemplate>
    <Layout class="pd-y-3">
      <FormData
        :edit="true"
        :routerBack="`/system/admin`"
        :ruleForm="ruleForm"
        :validate="true"
        @emitData="saveData"
      />
    </Layout>
  </DashboardTemplate>
</template>
<script>
import Layout from "@/template/LayoutDashboardTemplate";
import DashboardTemplate from "@/template/DashboardAdminTemplate";
import FormData from "@/components/formData/FormAdminData";
import { HTTP } from "@/service/axios";
export default {
  components: {
    DashboardTemplate,
    FormData,
    Layout
  },
  data() {
    return {
      ruleForm: {}
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    saveData(data) {
      let obj = {
        empId: this.$route.params.id,
        titleName: data.titleName,
        name: data.name,
        surname: data.surname,
        email: data.email,
        role: data.role,
        gender: data.gender,
        age: data.age,
        education: data.education,
        mobilephone: data.mobilephone
      };
      HTTP.defaults.headers.common["X-CSRF-TOKEN"] = this.$csrfToken;
      HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`;
      HTTP.put(`system/admin/emp`, obj)
        .then(res => {
          if (res.data.success) {
            this.$message({
              showClose: true,
              message: "แก้ไขข้อมูลสำเร็จ",
              type: "success"
            });
            this.$router.push("/system/admin");
          } else {
            this.alertCatchError(res.data.data);
          }
        })
        .catch(e => {
          if (e == "Error: Request failed with status code 401") {
            this.checkAuth();
          } else {
            if (e != "Error: timeout of 120000ms exceeded") {
              this.alertCatchError(e);
            }
          }
        });
    },
    getData ()
    {
      HTTP.defaults.headers.common["X-CSRF-TOKEN"] = this.$csrfToken;
      HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`;
      HTTP.get(`system/admin/emp/detail/${this.$route.params.id}`)
        .then(res => {
          if (res.data.success) {
            this.ruleForm = res.data.obj;
          }
        })
        .catch(err => {});
    }
  }
};
</script>